<template>
    <page-view class="fine-material" v-infinite-scroll="loadScroll" :infinite-scroll-disabled="disabled"
        :infinite-scroll-immediate="false" :up-class="'fine-material'">
        <div class="com-title">
            <div class="com-title-content">
                <div class="left" @click="toComIndex(comInfo.companyid)">
                    <img :src="comInfo?.logo" width="100%" alt="" />
                    <div class="name">{{ comInfo?.name }}</div>
                </div>
            </div>
        </div>
        <div class="fine-content">
            <div class="type-box">
                <div class="type-item" v-for="(item, index) in typeList" :key="index">
                    <span class="type">{{ item.name }}:</span>
                    <ul>
                        <li :class="{ active: typeIndexs[index] === null }" @click="changeAll(index)">
                            全部
                        </li>
                        <li v-for="itemVal in item.value" :class="{ active: typeIndexs[index] == itemVal.id }"
                            @click="changeClassType(index, itemVal)" :key="itemVal.id">
                            {{ itemVal.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="sort">
                <div class="sort-input">
                    <el-input placeholder="请输入内容" v-model="search" @change="searchInput" size="mini" clearable>
                        <el-button slot="append" icon="el-icon-search" @click="searchInput"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="fine-list">
                <div class="goods-water">
                    <div class="goods-content">
                        <waterfall :img-width="isMobile ? 175 : 260" :water-data="waterData" @on-click-water="toDetails">
                        </waterfall>
                        <el-empty description="暂无数据" v-if="waterData.length < 1"></el-empty>
                    </div>
                    <center v-if="loading">
                        <div class="bouncing-bar">
                            <div class="line"></div>
                            <div class="line"></div>
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                    </center>
                    <center v-if="noMore && waterData.length > 0">没有更多了</center>
                </div>
            </div>
        </div>
        <footer-com></footer-com>
    </page-view>
</template>
<script>
import { mapState } from "vuex";
import Waterfall from "@/components/waterfall/waterfall.vue";
export default {
    name: 'MyShops',
    components: { Waterfall },
    data() {
        return {
            comInfo:null,
            typeList: [
                {
                    name: "商品分类",
                    value: [],
                },
                {
                    name: "产品列表",
                    value: [],
                },
            ],
            typeIndexs: [null, null],
            search: "",
            total: 0, //总条数
            current: 0, //当前页,
            selectId: null, //当前请求id
            waterData: [],
            loading: false,
        };
    },
    computed: {
        ...mapState([ "screenWidth"]),
        isMobile() {
            if (this.screenWidth < 768) {
                return true;
            } else {
                return false;
            }
        },
        noMore() {
            return this.waterData.length >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
    },
    created() {
        this.getGoodsDetails(this.$route.query.id)
        this.getMaterClass();
        this.getMaterList();
    },
    methods: {
        //获取公司信息
        async getGoodsDetails(id) {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const { code, data } = await this.$api.getGoodsDetailsApi(id);
            loading.close();
            if (code === 200) {
                this.goodsDetails = data.goodsMain;
                document.title = `百川商展-闲物互联-${data.goodsMain.name}`;
                this.comInfo = {
                    name: data.goodsMain.companyName,
                    companyid: data.goodsMain.companyid,
                    logo: data.goodsMain.companyLogo,
                };
            } else {
                this.$message.error("获取商品详情失败");
            }
        },
        //获取分类
        async getMaterClass() {
            let result = await this.$api.getMaterClass(this.$route.query.syscompanyid);
            result.data.pop();
            this.$set(this.typeList[0], "value", result.data);
        },

        changeClassType(index, item) {
            if (index === 0) {
                //切换分类
                this.$set(this.typeIndexs, 0, item.id);
                this.$set(this.typeList[1], "value", item.children);
                this.$set(this.typeIndexs, 1, null);
                this.selectId = this.typeIndexs[0];
            } else {
                //切换产品
                this.$set(this.typeIndexs, 1, item.id);
                this.selectId = this.typeIndexs[1];
            }
            this.getMaterList();
        },
        // 切换全部
        changeAll(index) {
            if (index === 0) {
                this.$set(this.typeIndexs, 0, null);
                this.selectId = null;
            } else {
                this.$set(this.typeIndexs, 1, null);
                this.selectId = this.typeIndexs[0];
            }
            this.getMaterList();
        },

        //获取材料列表
        async getMaterList() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let result = await this.$api.getMaterList({
                companyid: this.$route.query.syscompanyid,
                categoryId: this.selectId,
                index: this.current,
                size: 10,
                name: this.search,
            });
            loading.close();
            this.total = result.data?.size;
            this.waterData = result.data?.list;
        },
        //搜索
        searchInput() {
            this.getMaterList();
        },
        //滚动分页
        async loadScroll() {
            if (this.disabled) {
                return false; //没有更多了
            }
            this.loading = true;
            this.current + 1;
            let result = await this.$api.getMaterList({
                companyid: this.comInfo.companyid,
                categoryId: this.selectId,
                index: this.current,
                size: 10,
                name: this.search,
            });
            this.loading = false;
            this.waterData.push(...result.data.list);
        },
        toComIndex(syscompanyid) {
            const openRoute = this.$router.resolve({
                name: "ComIndex",
                params: { syscompanyid },
            });
            window.open(openRoute.href, "_blank");
        },
        toDetails(v) {
            const openRoute = this.$router.resolve({
                name: "ArticlesDetailGood",
                params: {
                    goodsId: v.id,
                    isGoodFrom:'false',
                },
            });
            window.open(openRoute.href, "_blank");
        },
    },
};
</script>
<style lang='scss' scoped>
.fine-material {
    background-color: #f5f5f5;

    .com-title {
        background-color: #f6faff;
        padding: 20px 0;
        position: sticky;
        top: 0;
        z-index: 99;

        .com-title-content {
            width: $containerWidth;
            margin: 0px auto;
            @include flexbox(row, space-between, center);

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            .left {
                @include flexbox(row, flex-start, center);

                >img {
                    width: 58px;
                    height: 58px;
                    object-fit: contain;
                    cursor: pointer;
                }

                .name {
                    font-size: 14px;
                    margin: 0 20px;
                    cursor: pointer;
                }

                .btn {
                    background-color: #ffffff;
                    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 50px;
                    padding: 6px 25px;
                    font-size: 12px;
                }
            }
        }
    }

    .fine-content {
        box-sizing: border-box;
        width: $width;
        margin: auto;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        .type-box {
            background-color: #fff;
            padding: 20px;
            padding-bottom: 0px;
            box-sizing: border-box;
            font-size: 14px;

            .type-item {
                @include flexbox(row, flex-start, center);
                padding: 15px 0;

                .type {
                    color: #333;
                }

                >ul {
                    flex: 1;
                    @include flexbox(row);
                    flex-wrap: wrap;
                    margin-left: 10px;

                    >li {
                        margin-right: 10px;
                        line-height: 2;
                        cursor: pointer;
                        color: #787878;

                        &.active {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }

        .sort {
            background-color: #fff;
            padding: 15px 20px;
            padding-bottom: 20px;
            box-sizing: border-box;
            @include flexbox(row, space-between, center);
            flex-wrap: wrap;

            .sort-input {
                :deep(.el-input) {
                    .el-input-group__append {
                        background-color: $primaryColor;
                        color: #fff;
                        cursor: pointer;
                    }
                }
            }
        }

        .fine-list {
            // display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 20px;
            margin-top: 25px;

            .fine-item {
                background-color: #fff;
                cursor: pointer;

                .item-txt {
                    font-size: 14px;
                    padding: 10px;
                    box-sizing: border-box;

                    .item-com {
                        @include flexbox(row);
                        font-size: 12px;
                        margin-top: 10px;

                        .item-com-img {
                            width: 20px;
                            height: 20px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .goods-water {
            padding-bottom: 30px;

            .goods-content {
                width: $containerWidth;
                margin: auto;

                @media screen and (max-width: 1200px) {
                    width: 100%;
                }
            }

            .bouncing-bar {
                display: flex;
                justify-content: center;

                .line {
                    height: 20px;
                    width: 4px;
                    background: #439c4c;
                    margin-right: 4px;
                    border-radius: 6px;
                    animation: line-bounce 1s infinite ease-in-out;

                    &:nth-child(1) {
                        animation-delay: 0s;
                    }

                    &:nth-child(2) {
                        animation-delay: 0.2s;
                    }

                    &:nth-child(3) {
                        animation-delay: 0.3s;
                    }

                    &:nth-child(4) {
                        animation-delay: 0.4s;
                    }
                }
            }

            @keyframes line-bounce {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(1, 2);
                }

                40% {
                    transform: scale(1);
                }
            }
        }
    }
}
</style>